import request from '../request'

//在线登记列表
export const car_driver_appoint_staff_index_request = p => {
    return request({
        method:'GET',
        url:'car_driver_appoint_staff/index',
        params: p
    })
}

//在线登记详情
export const car_driver_appoint_staff_details_request = id => {
    return request({
        method:'GET',
        url:'car_driver_appoint_staff/details',
        params: {
            id
        }
    })
}

//在线登记分配
export const car_driver_appoint_staff_distribute_request = data => {
    return request({
        method:'PUT',
        url:'car_driver_appoint_staff/distribute',
        data
    })
}

//获取平台装卸情况
export const car_driver_appoint_staff_terrace_table_request = () => {
    return request({
        method:'GET',
        url:'car_driver_appoint_staff/terrace_table'
    })
}

//删除司机登记信息
export const car_driver_appoint_cancel_request = d => {
    return request({
        method:'PUT',
        url:'car_driver_appoint/cancel',
        data: d
    })
}

//短信通知车辆入场
export const car_driver_appoint_staff_send_phone_request = data => {
    return request({
        method:'PUT',
        url:'car_driver_appoint_staff/send_phone',
        data
    })
}

//开始装卸
export const car_driver_appoint_staff_load_start_request = data => {
    return request({
        method:'PUT',
        url:'car_driver_appoint_staff/load_start',
        data
    })
}

//结束装卸
export const car_driver_appoint_staff_load_end_request = data => {
    return request({
        method:'PUT',
        url:'car_driver_appoint_staff/load_end',
        data
    })
}