<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="状态" prop="state">
                <el-select
                    v-model="form.state"
                    placeholder="选择状态"
                    class="statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(v,i) in ['待分配','已分配','装卸中','已完成','已取消','已过期']"
                        :key="v"
                        :label="v"
                        :value="i"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="装卸平台" prop="with_terrace_id">
                <el-select
                    v-model="form.with_terrace_id"
                    placeholder="选择装卸平台"
                    class="with_terrace_idc"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="iteam in AllTerrace"
                        :key="iteam.id"
                        :label="iteam.name"
                        :value="iteam.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="车牌号" prop="car_number">
                <el-input
                    v-model="form.car_number"
                    placeholder="车牌号搜索"
                    class="car_numberc"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="集装箱号" prop="box_num">
                <el-input
                    v-model="form.box_num"
                    placeholder="集装箱号搜索"
                    class="box_numc"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="司机姓名" prop="car_driver_name">
                <el-input
                    v-model="form.car_driver_name"
                    placeholder="司机姓名搜索"
                    class="car_driver_namec"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
                <el-button type="success" plain @click="PlanVisible = true"
                    >查看平台情况</el-button
                >
            </el-form-item>
            <!-- 平面图 -->
            <car-driver-appoint-staff-plan-component
                :PlanVisible="PlanVisible"
                :AllTerrace="AllTerrace"
                @exitPlanDialog="PlanVisible = false"
            ></car-driver-appoint-staff-plan-component>
        </el-form>
    </el-card>
</template>

<script>
import CarDriverAppointStaffPlanComponent from './CarDriverAppointStaffPlanComponent'

export default {
    name: 'CarBusinessTypeSearchBarComponent',
    data() {
        return {
            fdialogFormVisible: false,
            form: {
                state: '',
                with_terrace_id: '',
                car_number: '',
                box_num: '',
                car_driver_name: '',
                page: 1,
            },
            PlanVisible: false
        }
    },
    computed: {},
    methods: {
        search() {
            this.$emit('search', this.form);
        },
        resetbtn(f) {
            this.$refs[f].resetFields();
        }
    },
    filters: {},
    props: {
        AllTerrace: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CarDriverAppointStaffPlanComponent
    },
    watch: {},
};
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>
