<template>
    <div>
        <el-dialog
            title="登记详情"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="60%"
            :center="true"
            top="5vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="CarDriverAppointStaff"    
                    ref="CarDriverAppointStaffEditForm"
                    label-width="120px"
                    size="small"
                >
                    <div>
                        <el-descriptions
                            class="margin-top"
                            :column="2"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>状态</span>
                                </template>
                                <el-tag v-if="CarDriverAppointStaff.state===0" type="info" size="small">待分配</el-tag>
                                <el-tag v-else-if="CarDriverAppointStaff.state===1" size="small">已分配</el-tag>
                                <el-tag v-else-if="CarDriverAppointStaff.state===2" type="warning" size="small">装卸中</el-tag>
                                <el-tag v-else-if="CarDriverAppointStaff.state===3" type="success" size="small">已完成</el-tag>
                                <el-tag v-else-if="CarDriverAppointStaff.state===4" type="danger" size="small">已取消</el-tag>
                                <el-tag v-else-if="CarDriverAppointStaff.state===5" type="danger" size="small">已过期</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>业务类型</span>
                                </template>
                                <span>{{CarDriverAppointStaff.car_business_type}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>车辆类型</span>
                                </template>
                                <span>{{CarDriverAppointStaff.car_size_type}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>车牌号</span>
                                </template>
                                <span>{{CarDriverAppointStaff.car_number}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>集装箱号</span>
                                </template>
                                <span>{{CarDriverAppointStaff.box_num}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>司机姓名</span>
                                </template>
                                <span>{{CarDriverAppointStaff.car_driver_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>司机手机号</span>
                                </template>
                                <span>{{CarDriverAppointStaff.car_driver_phone}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>司机身份证</span>
                                </template>
                                <span>{{CarDriverAppointStaff.car_driver_icard}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>排队号</span>
                                </template>
                                <span>{{CarDriverAppointStaff.list_num}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>装卸平台</span>
                                </template>
                                <span>{{CarDriverAppointStaff.with_terrace_name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>装卸开始时间</span>
                                </template>
                                <span>{{CarDriverAppointStaff.start_time}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>装卸结束时间</span>
                                </template>
                                <span>{{CarDriverAppointStaff.end_time}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>身份证正面</span>
                                </template>
                                <img
                                    v-if="CarDriverAppointStaff.icard_file_a[0]['url']"
                                    :src="CarDriverAppointStaff.icard_file_a[0]['url']"
                                    @click="showIMG(CarDriverAppointStaff.icard_file_a[0]['url'])"
                                    height="100px"
                                />
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>身份证反面</span>
                                </template>
                                <img
                                    v-if="CarDriverAppointStaff.icard_file_b[0]['url']"
                                    :src="CarDriverAppointStaff.icard_file_b[0]['url']"
                                    @click="showIMG(CarDriverAppointStaff.icard_file_b[0]['url'])"
                                    height="100px"
                                />
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>驾驶证主页</span>
                                </template>
                                <img
                                    v-if="CarDriverAppointStaff.jscard_file_a[0]['url']"
                                    :src="CarDriverAppointStaff.jscard_file_a[0]['url']"
                                    @click="showIMG(CarDriverAppointStaff.jscard_file_a[0]['url'])"
                                    height="100px"
                                />
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>驾驶证副页</span>
                                </template>
                                <img
                                    v-if="CarDriverAppointStaff.jscard_file_b[0]['url']"
                                    :src="CarDriverAppointStaff.jscard_file_b[0]['url']"
                                    @click="showIMG(CarDriverAppointStaff.jscard_file_b[0]['url'])"
                                    height="100px"
                                />
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>行驶证正面</span>
                                </template>
                                <img
                                    v-if="CarDriverAppointStaff.xscard_file_a[0]['url']"
                                    :src="CarDriverAppointStaff.xscard_file_a[0]['url']"
                                    @click="showIMG(CarDriverAppointStaff.xscard_file_a[0]['url'])"
                                    height="100px"
                                />
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>行驶证反面</span>
                                </template>
                                <img
                                    v-if="CarDriverAppointStaff.xscard_file_b[0]['url']"
                                    :src="CarDriverAppointStaff.xscard_file_b[0]['url']"
                                    @click="showIMG(CarDriverAppointStaff.xscard_file_b[0]['url'])"
                                    height="100px"
                                />
                            </el-descriptions-item>
                        </el-descriptions>
                        <!-- 图片放大 -->
                        <el-dialog
                            :visible.sync="dialogVisible"
                            :center="true"
                            :append-to-body="true"
                        >
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                        <!-- 平面图 -->
                        <car-driver-appoint-staff-plan-component
                            :id="id"
                            :PlanVisible="PlanVisible"
                            :AllTerrace="AllTerrace"
                            :CarInformation="CarDriverAppointStaff"
                            @exitPlanDialog="exitPlanDialog"
                        ></car-driver-appoint-staff-plan-component>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div>
                    <el-button
                        v-if="this.$_has('在线登记分配') && (this.CarDriverAppointStaff.state === 0)"
                        type="primary"
                        plain
                        @click="PlanVisible = true"
                        >分配平台</el-button
                    >
                    <el-button
                        v-if="this.$_has('在线登记分配') && (this.CarDriverAppointStaff.state === 1)"
                        type="primary"
                        plain
                        @click="PlanVisible = true"
                        >更换平台</el-button
                    >
                    <el-button
                        v-if="this.$_has('短信通知车辆入场') && (this.CarDriverAppointStaff.state === 1) && (this.CarDriverAppointStaff.notice === 0)"
                        type="warning"
                        plain
                        @click="send_phone"
                        >通知入场</el-button
                    >
                    <el-button
                        v-if="this.$_has('短信通知车辆入场') && (this.CarDriverAppointStaff.state === 1) && (this.CarDriverAppointStaff.notice === 1)"
                        plain
                        >已通知</el-button
                    >
                    <el-button
                        v-if="this.CarDriverAppointStaff.state === 0"
                        type="danger"
                        plain
                        @click="del_driver_appoint"
                        >删除登记</el-button
                    >
                    <el-button
                        v-if="this.$_has('车辆开始装卸') && (this.CarDriverAppointStaff.state === 1)"
                        type="success"
                        plain
                        @click="load_start"
                        >开始装卸</el-button
                    >
                    <el-button
                        v-if="this.$_has('车辆结束装卸') && (this.CarDriverAppointStaff.state === 2)"
                        type="danger"
                        plain
                        @click="load_end"
                        >装卸完成</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import CarDriverAppointStaffPlanComponent from './CarDriverAppointStaffPlanComponent'

import { 
    car_driver_appoint_cancel_request,
    car_driver_appoint_staff_send_phone_request,
    car_driver_appoint_staff_load_start_request,
    car_driver_appoint_staff_load_end_request
} from '@/network/WareHouse/CarDriverAppointStaff'

export default {
    name: 'CarDriverAppointStaffEditComponent',
    data() {
        return {
            login_loading: false,
            CarDriverAppointStaff: {
                state: '',
                car_business_type: '',
                car_size_type: '',
                car_number: '',
                box_num: '',
                car_driver_name: '',
                car_driver_phone: '',
                car_driver_icard: '',
                list_num: '',
                start_time: '',
                end_time: '',
                with_terrace_name: '',
                icard_file_a: [{name:'',url:''}],
                icard_file_b: [{name:'',url:''}],
                jscard_file_a: [{name:'',url:''}],
                jscard_file_b: [{name:'',url:''}],
                xscard_file_a: [{name:'',url:''}],
                xscard_file_b: [{name:'',url:''}]
            },
            dialogVisible: false,
            dialogImageUrl: '',
            PlanVisible: false
        }
    },
    computed: {},
    methods: {
        closeDialog() {
            this.CarDriverAppointStaff = {
                state: '',
                car_business_type: '',
                car_size_type: '',
                car_number: '',
                box_num: '',
                car_driver_name: '',
                car_driver_phone: '',
                car_driver_icard: '',
                list_num: '',
                start_time: '',
                end_time: '',
                with_terrace_name: '',
                icard_file_a: [{name:'',url:''}],
                icard_file_b: [{name:'',url:''}],
                jscard_file_a: [{name:'',url:''}],
                jscard_file_b: [{name:'',url:''}],
                xscard_file_a: [{name:'',url:''}],
                xscard_file_b: [{name:'',url:''}]
            }
            this.$emit('exitDialog')
            this.$emit('search')
        },
        showIMG(url) {
            this.dialogVisible = true
            this.dialogImageUrl = url
        },
        exitPlanDialog() {
            this.PlanVisible = false
            this.$emit('refresh',this.id)
        },
        del_driver_appoint() {
            this.$confirm(
                '是否确定删除登记？（删除后，只能由司机重新再进行登记）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    car_driver_appoint_cancel_request({id: this.id})
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.closeDialog())
                })
                .catch((err) => {})
        },
        send_phone() {
            car_driver_appoint_staff_send_phone_request({ id: this.id })
                .then((s) => {
                    if (s.status === 0) {
                        this.$message.success('已发送短信通知')
                        this.CarDriverAppointStaff.notice = 1
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        load_start() {
            car_driver_appoint_staff_load_start_request({ id: this.id })
                .then((s) => {
                    if (s.status === 0) {
                        this.$message.success('已开始装卸')
                        this.CarDriverAppointStaff.state = 2
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        load_end() {
            car_driver_appoint_staff_load_end_request({ id: this.id })
                .then((s) => {
                    if (s.status === 0) {
                        this.$message.success('已结束装卸')
                        this.CarDriverAppointStaff.state = 3
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        car_driver_appoint_staff_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        AllTerrace: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created() {},
    beforeDestroy() {},
    mounted() {},
    updated() {},
    components: {
        CarDriverAppointStaffPlanComponent
    },
    watch: {
        car_driver_appoint_staff_details_data(newVal) {
            this.CarDriverAppointStaff = newVal
        },
    },
}
</script>

<style lang='less'>
.statec {
    width: 100%;
}
</style>