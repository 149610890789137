<template>
    <div v-loading.fullscreen.lock="loading">
        <car-driver-appoint-staff-search-bar-component
            :AllTerrace="AllTerrace"
            @search="get_car_driver_appoint_staff_index"
        ></car-driver-appoint-staff-search-bar-component>
        <common-table-component
            details_auth="在线登记详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_car_driver_appoint_staff"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_car_driver_appoint_staff_index"
        ></common-page-component>
        <car-driver-appoint-staff-edit-component
            :id="fid"
            :dialogFormVisible="fdialogFormVisible"
            :car_driver_appoint_staff_details_data="fcar_driver_appoint_staff_details_data"
            :AllTerrace="AllTerrace"
            @exitDialog="dialogExit"
            @search="get_car_driver_appoint_staff_index"
            @details_row="details_car_driver_appoint_staff"
            @refresh="refresh"
        ></car-driver-appoint-staff-edit-component>
    </div>
</template>

<script>
import { car_driver_appoint_staff_index_request,car_driver_appoint_staff_details_request } from "@/network/WareHouse/CarDriverAppointStaff.js"
import { terrace_list_request } from "@/network/list.js"

import CarDriverAppointStaffSearchBarComponent from "@/components/WareHouse/CarDriverAppointStaff/CarDriverAppointStaffSearchBarComponent"
import CommonTableComponent from "@/components/common/CommonTableComponent"
import CommonPageComponent from "@/components/common/CommonPageComponent"
import CarDriverAppointStaffEditComponent from "@/components/WareHouse/CarDriverAppointStaff/CarDriverAppointStaffEditComponent"

export default {
    name: 'CarDriverAppointStaffView',
    data() {
        return {
            fid: 0,
            fdialogFormVisible: false,
            loading: false,
            ftotal: 0,
            cond: {
                state: '',
                with_terrace_id: '',
                car_number: '',
                box_num: '',
                car_driver_name: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'state',
                    label: '状态',
                    minWidth: '80px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待分配'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '已分配'
                            }
                        } else if(d===2) {
                            return {
                                type: 'warning',
                                filter_data: '装卸中'
                            }
                        } else if(d===3) {
                            return {
                                type: 'success',
                                filter_data: '已完成'
                            }
                        } else if(d===4) {
                            return {
                                type: 'danger',
                                filter_data: '已取消'
                            }
                        } else if(d===5) {
                            return {
                                type: 'danger',
                                filter_data: '已过期'
                            }
                        }
                    }
                },
                {
                    prop: 'list_num',
                    label: '排队号',
                    minWidth: '100px',
                },
                {
                    prop: 'with_terrace_name',
                    label: '装卸平台',
                    minWidth: '220px',
                },
                {
                    prop: 'car_business_type',
                    label: '业务类型',
                    minWidth: '100px',
                },
                {
                    prop: 'car_size_type',
                    label: '车辆大小',
                    minWidth: '100px',
                },
                {
                    prop: 'car_number',
                    label: '车牌号',
                    minWidth: '100px',
                },
                {
                    prop: 'box_num',
                    label: '集装箱号',
                    minWidth: '110px',
                },
                {
                    prop: 'car_driver_name',
                    label: '司机姓名',
                    minWidth: '100px',
                },
                {
                    prop: 'car_driver_phone',
                    label: '司机电话',
                    minWidth: '100px',
                },
            ],
            fcar_driver_appoint_staff_details_data: {},
            AllTerrace: [],
            timer: false
        };
    },
    computed: {},
    methods: {
        get_car_driver_appoint_staff_index(param = {}) {
            this.cond.state = param.state ?? this.cond.state;
            this.cond.with_terrace_id = param.with_terrace_id ?? this.cond.with_terrace_id;
            this.cond.car_number = param.car_number ?? this.cond.car_number;
            this.cond.box_num = param.box_num ?? this.cond.box_num;
            this.cond.car_driver_name = param.car_driver_name ?? this.cond.car_driver_name;
            this.cond.page = param.page ?? this.cond.page;
            if (param.limit) {
            this.cond.limit = param.limit;
            this.cond.page = 1;
            }
            car_driver_appoint_staff_index_request(this.cond)
            .then((s) => {
                if (s.status === 0) {
                this.ftable_data = s.result.data;
                this.ftotal = s.result.total;
                } else {
                this.$message.error(s.msg);
                }
            })
            .catch((err) => {
                this.$message.error(err);
            });
        },
        details_car_driver_appoint_staff(id) {
            this.fid = id;
            this.fdialogFormVisible = true;
            car_driver_appoint_staff_details_request(id)
            .then((s) => {
                if (s.status === 0) {
                this.fcar_driver_appoint_staff_details_data = s.result;
                } else {
                this.$message.error(s.msg);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
        },
        get_all_terrace_list() {
            terrace_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.AllTerrace = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        refresh(id) {
            this.details_car_driver_appoint_staff(id)
            this.get_car_driver_appoint_staff_index()
        },
        start_timer() {
            this.timer = true
            const changeSendTime = setInterval(()=>{
                if(this.timer) {
                    this.get_car_driver_appoint_staff_index()
                } else {
                    clearInterval(changeSendTime)
                }
            },5000)
        },
        end_timer() {
            this.timer = false
        }
    },
    filters: {},
    props: {},
    created() {
        this.get_car_driver_appoint_staff_index()
        this.get_all_terrace_list()
        this.start_timer()
    },
    beforeDestroy() {
        this.end_timer()
    },
    mounted() {},
    updated() {},
    components: {
        CarDriverAppointStaffSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        CarDriverAppointStaffEditComponent,
    },
    watch: {},
};
</script>

<style lang="less"></style>
