<template>
    <el-dialog
        title="各平台车辆情况"
        :visible.sync="PlanVisible"
        width="80%"
        top="3vh"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => close_plan_dialog()"
    >
        <div style="color: #FF0000;font-weight: bold;" v-if="CarInformation.car_driver_name">您正在进行车辆分配：【司机：{{CarInformation.car_driver_name}}】【车牌号：{{CarInformation.car_number}}】【集装箱号:{{CarInformation.box_num ? CarInformation.box_num : '无'}}】</div>
        <el-row :gutter="20" v-for="row in Rows" :key="row">
            <div v-for="col in 3" :key="col">
                <el-col :span="8" v-if="col_show(row,col)">
                    <fieldset class="fieldsetc">
                    <legend class="legendc">{{fieldset_name(row,col)}}</legend>
                        <el-table
                            :data="fieldset_data(row,col)"
                            border
                            highlight-current-row
                            style="width: 99%"
                            size="mini"
                            height="260"
                            empty-text="暂无排队车辆"
                        >
                            <el-table-column
                                align="center"
                                label="排队号"
                                min-width="90px"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row['list_num'] }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="状态"
                                min-width="70px"
                            >
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row['state']===0" type="info" size="small">待分配</el-tag>
                                    <el-tag v-else-if="scope.row['state']===1" size="small">已分配</el-tag>
                                    <el-tag v-else-if="scope.row['state']===2" type="warning" size="small">装卸中</el-tag>
                                    <el-tag v-else-if="scope.row['state']===3" type="success" size="small">已完成</el-tag>
                                    <el-tag v-else-if="scope.row['state']===4" type="danger" size="small">已取消</el-tag>
                                    <el-tag v-else-if="scope.row['state']===5" type="danger" size="small">已过期</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="车辆类型"
                                min-width="90px"
                                :show-overflow-tooltip="true"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row['car_size_type'] }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="车牌号"
                                min-width="70px"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row['car_number'] }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="集装箱号"
                                min-width="90px"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row['box_num'] }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="text-align: center;margin-top: 5px;">
                            <el-button
                                v-if="id"
                                type="primary"
                                plain
                                size="small"
                                @click="line_up(row,col)"
                                >在这排队</el-button
                            >
                        </div>
                    </fieldset>
                </el-col>
            </div>
        </el-row>
    </el-dialog>
</template>

<script>
import { car_driver_appoint_staff_terrace_table_request,car_driver_appoint_staff_distribute_request } from "@/network/WareHouse/CarDriverAppointStaff.js"

export default {
    name:'CarDriverAppointStaffPlanComponent',
    data(){
        return {
            TerraceTable: [],
            TableData: []
        }
    },
    computed:{
        Rows() {
            return Math.ceil(this.AllTerrace.length/3)
        }
    },
    methods:{
        close_plan_dialog() {
            this.$emit('exitPlanDialog')
            this.get_terrace_table()
        },
        get_terrace_table() {
            if(!this.$_has('在线登记分配')) {
                return
            }
            car_driver_appoint_staff_terrace_table_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.TerraceTable = s.result
                        
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                }).finally(() => this.get_plan(this.AllTerrace,this.TerraceTable))
        },
        get_plan(AllTerrace,TerraceTable) {
            let res = []
            let arr = []
            AllTerrace.forEach(item => {
                arr = TerraceTable.filter(v => v.with_terrace_id===item.id)
                res.push(arr)
            })
            
            this.TableData = res
        },
        col_show(row,col) {
            return (row-1)*3 + col -1 < this.AllTerrace.length
        },
        fieldset_name(row,col) {
            const index = (row-1)*3 + col -1
            return this.AllTerrace[index] ? this.AllTerrace[index]['name'] : ''
        },
        fieldset_data(row,col) {
            const index = (row-1)*3 + col -1
            return this.TableData[index]
        },
        line_up(row,col) {
            const index = (row-1)*3 + col - 1
            let data = {}
            data['id'] = this.id
            data['with_terrace_id'] = this.AllTerrace[index]['id']

            car_driver_appoint_staff_distribute_request(data).then((s) => {
                if (s.status === 0) {
                    this.$message.success('分配成功!')
                    this.get_terrace_table()
                } else {
                    this.$message.error(s.msg)
                }
            }).catch((err) => {
                this.$message.error(err)
            })
        },
    },
    filters:{},
    props:{
        id: {
            type: Number,
            default() {
                return 0
            }
        },
        PlanVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        AllTerrace: {
            type: Array,
            default() {
                return []
            }
        },
        CarInformation: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    created(){
        this.get_terrace_table()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style>
.fieldsetc {
    margin-bottom: 10px;
}
</style>